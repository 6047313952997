<template>
  <v-container class="fill-height">
    <v-snackbar
      v-model="snackBar"
      :color="snackColor"
      :timeout="snackBarTimeout"
      :left="snackBarX === 'left'"
      :right="snackBarX === 'right'"
      :top="snackBarY === 'top'"
    >
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackBar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col class="mb-4">
        <!-- need to look at adding :rules -->
        <v-col cols="12" sm="12" class="pa-0">
          <v-card width="500px" class="mx-auto pa-5">
            <v-card-title class="pa-3 font-weight-bold"> Upload Blitz File... </v-card-title>
            <v-file-input
              display-size
              placeholder="Select Blitz file for upload."
              accept="xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="uploadFile"
            />
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" class="pa-0">
          <v-card width="500px" class="mx-auto pa-5">
            <v-card-title class="pa-3 font-weight-bold"> Upload Buyback Price File... </v-card-title>
            <v-file-input
              display-size
              v-model="buybackFile"
              ref="buybackFileInput"
              placeholder="Select Buyback price file for upload."
              accept="xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="uploadBuybackFile"
            />
          </v-card>
        </v-col>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1>{{ headerText }}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import readXlsxFile from 'read-excel-file'; //need to remove this from package.json
import XLSX from 'xlsx'
import moment from 'moment'
import { loadData, loadBuybackData, resetGlobalAccountFilter } from '@/api/Preprocessor'
import { deleteDatabase, getLocalUserList } from '@/api/PouchDao'
import { createNamespacedHelpers } from 'vuex'
import store from '@/store'
const { mapGetters } = createNamespacedHelpers('app')

export default {
  name: 'Settings',
  components: {},
  data() {
    return {
      showProcessingLog: false,
      showFilUpload: false,
      snackBar: false,
      snackColor: 'success',
      snackBarTimeout: 6000,
      snackBarX: 'right',
      snackBarY: 'top',
      snackMessage: '',
      headerText: 'Upload your blitz here',
      newFileName: 'blitz file',
      buybackFile: null,
      rules: [
        // TODO need to revisit this, the error message appears all the time
        (value) => !value || value.size < 1100000000 || 'File exceeded 11 MB max limit. Please contact support!'
      ]
    }
  },
  computed: {
    ...mapGetters(['snCount', 'importDate', 'fileName', 'progress', 'dataLoaded', 'buybackAvailable']),
    showProgress() {
      return this.progress
    }
  },
  watch: {
    snCount: {
      handler() {
        console.log('snCount changed')
      }
    },
    dataLoaded: {
      handler() {
        console.log('dataLoaded ', this.dataLoaded)
        if (this.dataLoaded) {
          this.snackColor = 'success'
          this.snackMessage = this.newFileName + ' loaded'
          this.snackBar = true
          this.headerText = 'Imported ' + this.snCount + ' services from ' + this.newFileName
        }
      }
    }
  },
  mounted() {
    console.log('Settings.vue mounted snCount is ' + this.snCount)
    // const userList = getLocalUserList()
    const importedDate = moment(new Date(this.importDate))
    if (this.snCount) {
      this.headerText = this.snCount + ' services imported from ' + this.fileName + ' on ' + importedDate.format('DD MMM YYYY HH:mm')
    }
    this.$gtag.pageview({
      page_path: '/uploadFile'
    })
    this.showProcessingLog = false
  },
  methods: {
    uploadFile: function (e) {
      const bfile = e
      this.$store.dispatch('app/setProgress', true) // dispatch to the setProgress action in the store
      console.log('uploadFile clicked file name is ' + bfile.name + ' show progress is ' + this.progress)
      this.newFileName = bfile.name
      this.$gtag.event('uploadFile', {
        event_category: 'fileName',
        event_label: this.newFileName
      })
      let fileReader = new FileReader()
      fileReader.onload = function (e) {
        // pre-process data
        let binary = ''
        let bytes = new Uint8Array(e.target.result)
        let length = bytes.byteLength
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        console.log('uploadFile finish reading binary')
        // call 'xlsx' to read the file
        let wb = XLSX.read(binary, {
          type: 'binary',
          cellDates: true,
          cellStyles: true
        })
        const ws = wb.Sheets[wb.SheetNames[0]]
        const rows = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          dateNF: 'DD/MM/YYYY'
        })
        console.log('calling preprocessor... fileReader completed loading xls ' + bfile.name + ' found ' + rows.length + ' rows')
        //Add demo data for test
        const partner = {
          partnerBlitzLogo: '',
          partnerBlitzIcon: '',
          navbarTitle: 'Blitz Demo',
          sidebarColor: '#363636',
          activeButtonColor: '#E91E63',
          accessKey: ''
        }
        // calls Preprocessor loadData() function to load data
        return loadData(bfile.name, rows, partner)
          .then((result) => {
            console.log('completed importing data from ' + bfile.name)
            // console.log(result)
            // console.log('snCount is now ' + this.snCount)
            return Promise.resolve(result)
          })
          .then((result) => {
            this.showProcessingLog = true
            store.dispatch('app/setFirstTimeUse', false)
            return resetGlobalAccountFilter()
          })
      }
      fileReader.readAsArrayBuffer(bfile) // action the instructions provided above..
      console.log('upload loadfile finished processing file now resetting global filter')
      // this.$store.dispatch('app/resetGlobalFilterToAll')
    },
    uploadBuybackFile: function (e) {
      const me = this
      const bfile = e
      if (e) {
        this.$store.dispatch('app/setProgress', true) // dispatch to the setProgress action in the store
        console.log('upload buyback price clicked file name is ' + bfile.name + ' show progress is ' + this.progress)
        this.newFileName = bfile.name
        let fileReader = new FileReader()
        fileReader.onload = function (e) {
          // pre-process data
          let binary = ''
          let bytes = new Uint8Array(e.target.result)
          const length = bytes.byteLength
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          console.log('upload buyback finish reading binary')
          // call 'xlsx' to read the file
          let wb = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true
          })
          const ws = wb.Sheets[wb.SheetNames[0]]
          const rows = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            dateNF: 'DD/MM/YYYY'
          })
          console.log('calling preprocessor... fileReader completed loading buyback xls ' + bfile.name + ' found ' + rows.length + ' rows')
          return loadBuybackData(bfile.name, rows)
            .then((result) => {
              console.log('completed importing buyback price data from ' + bfile.name)
              store.dispatch('app/setProgress', false)
              store.dispatch('app/setBuybackAvailable', true)
              me.$refs.buybackFileInput.reset()
              me.buybackFile = null
              me.snackColor = 'success'
              me.snackMessage = me.newFileName + ' loaded'
              me.snackBar = true
              return Promise.resolve(result)
            })
            .catch((error) => {
              store.dispatch('app/setProgress', false)
              store.dispatch('app/setBuybackAvailable', false)
              me.$refs.buybackFileInput.reset()
              me.buybackFile = null
              me.snackColor = 'error'
              me.snackMessage = me.newFileName + ' not loaded'
              me.snackBar = true
              console.error(error)
            })
        }
        fileReader.readAsArrayBuffer(bfile) // action the instructions provided above..
        console.log('upload buyback price file finished processing file now resetting global filter')
      }
    },
    deleteDatabase: function () {
      deleteDatabase()
    }
  }
}
</script>

<style scoped></style>
